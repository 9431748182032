
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SnowplowContext, SnowplowTitleContextGraphql } from '@/helpers/tracking/providers';
import {
	SetInSeenlistDocument,
	SetInSeenlistMutation,
	SetInSeenlistMutationVariables,
} from '@/graphql/mutation/SetInSeenlist.mutation';
import { TitleListName } from '@/interfaces/responses/title-list';
import { ObjectType, Platform, WatchNowOfferFilter } from '@/@types/graphql-types';
import { TrackingHelper, TrackingListEventPayload } from '@/helpers/tracking/tracking-helper';

import { ModalHelper } from '@/helpers/modal-helper';
import LikeDislikePollModal from '@/components/modals/LikeDislikePollModal.vue';
import { addTitleToListToast, getSeenToastMessage, removeTitleFromListToast } from '@/helpers/toast/toast-helper';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import {
	TitleQuickActionPayload,
	ListMutationShowDetailParam,
	ListMutationTitleDetailParam,
} from '@/helpers/providers/title-actions-provider';

const Language = namespace('language');

@Component
export default class TitleSeenlistMutationMixin extends Vue {
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];

	@Language.Getter country: string;
	@Language.Getter language: string;

	async mixin_setInSeenlist(
		titleId: string,
		isTitleInList: boolean,
		titleDetails: ListMutationTitleDetailParam & ListMutationShowDetailParam,
		// This is only for set in seenlist WatchNextEpisode
		// When we set as seen WatchNextEpisode, we get whole show in response that graphql uses to update existing show cache
		// It will update existing show cache with new WatchNextEpisode, that has to contain correct WatchNowOffer (with correct filters applied)
		watchNowFilter: WatchNowOfferFilter = {},
		trackingPayload?: {
			source?: string;
			property?: string;
		},
		refetchQueryList?: string[]
	) {
		const preventAction = await preventNonSignedInAction();

		if (preventAction) {
			return null;
		}

		return this.$apollo
			.mutate<SetInSeenlistMutation, SetInSeenlistMutationVariables>({
				mutation: SetInSeenlistDocument,
				variables: {
					input: {
						id: titleId,
						state: !isTitleInList,
						country: this.country,
					},
					country: this.country,
					language: this.language,
					watchNowFilter: watchNowFilter,
					includeUnreleasedEpisodes: false,
					platform: Platform.Web,
				},
				refetchQueries: refetchQueryList,
				update: () => {
					const source = trackingPayload?.source ?? '';
					const contexts = [...this.additionalContexts];
					const hasTitleContext = contexts.find(context => context.__name === 'title');

					if (!hasTitleContext) {
						contexts.push(
							new SnowplowTitleContextGraphql(
								titleDetails.showObjectId || titleDetails.objectId,
								titleDetails.objectType === ObjectType.ShowSeason
									? ObjectType.Show
									: titleDetails.objectType,
								titleDetails.seasonNumber,
								titleDetails.episodeNumber,
								titleDetails.contentType
							)
						);
					}

					if (trackingPayload) {
						const payload: TrackingListEventPayload = {
							action: source,
						};

						if (trackingPayload?.property) {
							payload.property = trackingPayload.property;
						}

						!isTitleInList
							? this.trackSeenlistAddEvent(payload, contexts)
							: this.trackSeenlistRemoveEvent(payload, contexts);
					}

					if (!isTitleInList) {
						if (titleDetails.objectType === ObjectType.Show) {
							ModalHelper.openModal(
								LikeDislikePollModal,
								{
									closable: true,
									modalTitle: getSeenToastMessage(isTitleInList, {
										titleName: titleDetails.title,
										titleObjectType: titleDetails.objectType,
										showEpisode: titleDetails.episodeNumber,
										showSeason: titleDetails.seasonNumber,
									}),
									titleId,
									titleDetails,
								},
								{ cssClass: 'jw-poll-modal', id: 'like_dislike_poll_modal' }
							);
						} else {
							addTitleToListToast(TitleListName.SEENLIST, titleDetails);
						}
					} else {
						removeTitleFromListToast(TitleListName.SEENLIST, titleDetails);
					}
				},
			})
			.then(() => {
				const payload: TitleQuickActionPayload = {
					titleObjectId: titleDetails.objectId,
					type: TitleListName.SEENLIST,
					isTitleInList: isTitleInList,
					objectType: titleDetails.objectType,
				};
				this.$emit('title-quick-action', payload);
			});
	}

	trackSeenlistAddEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.SEENLIST, 'add', payload, contexts);
	}

	trackSeenlistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.SEENLIST, 'remove', payload, contexts);
	}
}
