import {
	SetInWatchlistDocument,
	SetInWatchlistMutation,
	SetInWatchlistMutationVariables,
} from '@/graphql/mutation/SetInWatchlist.mutation';
import { SnowplowContext, SnowplowTitleContextGraphql } from '@/helpers/tracking/providers';
import { computed, getCurrentInstance } from 'vue';

import { ObjectType } from '@/@types/graphql-types';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import { addTitleToListToast, removeTitleFromListToast } from '@/helpers/toast/toast-helper';
import { TrackingHelper, TrackingListEventPayload } from '@/helpers/tracking/tracking-helper';
import { TitleListName } from '@/interfaces/responses/title-list';

import { ImpressionTrackingEvents } from '@/enums/events';
import { ListMutationTitleDetailParam, TitleQuickActionPayload } from '@/helpers/providers/title-actions-provider';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import { useLanguageStore, useUserStore } from './useStores';
import { getVm } from '../vm-helper';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';

export function useTitleWatchlist(
	sponsoredAd?: SponsoredAdFragment,
	additionalContexts: SnowplowContext[] = [],
	trackingCallback?: (payload: TitleQuickActionPayload) => void
) {
	const { country } = useLanguageStore();
	const { isLoggedIn } = useUserStore();

	const isSponsoredRecommendationCohort = computed(() => sponsoredAd && !sponsoredAd.holdoutGroup);
	const sponsoredRecommendationPackageId = computed(() => sponsoredAd?.campaign?.watchNowOffer.package.packageId);

	async function onIsLoggedInChange(
		titleId: string,
		details: { objectId: number; objectType: ObjectType; title: string },
		isTitleInWatchlist: boolean,
		source: string
	) {
		if (process.client) {
			if (isLoggedIn.value() && localStorage.getItem('notifyCallback') === titleId.toString()) {
				setInWatchlist(titleId, isTitleInWatchlist, details, { source });

				localStorage.removeItem('notifyCallback');
			}
		}
	}

	async function setInWatchlist(
		titleId: string,
		isTitleInList: boolean,
		titleDetails: ListMutationTitleDetailParam,
		trackingPayload?: {
			source?: string;
			property?: string;
		}
	) {
		if (process.client && !isLoggedIn.value()) localStorage.setItem('notifyCallback', titleId.toString());

		const preventAction = await preventNonSignedInAction();

		if (preventAction) {
			return null;
		}

		// Currently unsupported
		if (titleDetails.objectType === ObjectType.ShowSeason) return;

		const { proxy: instance } = getCurrentInstance() ?? { proxy: getVm() };
		const dollarApollo = instance.$apollo as DollarApollo<typeof instance>;

		return dollarApollo
			.mutate<SetInWatchlistMutation, SetInWatchlistMutationVariables>({
				mutation: SetInWatchlistDocument,
				variables: {
					input: {
						id: titleId,
						state: !isTitleInList,
					},
					country: country.value,
				},
				update: () => {
					const source = trackingPayload?.source ?? '';

					const contexts = [...additionalContexts];
					const hasTitleContext = contexts.find(context => context.__name === 'title');

					if (!hasTitleContext) {
						contexts.push(
							new SnowplowTitleContextGraphql(
								titleDetails.objectId,
								titleDetails.objectType,
								null,
								null,
								titleDetails.contentType
							)
						);
					}

					if (trackingPayload) {
						const payload: TrackingListEventPayload = {
							action: source,
						};

						if (trackingPayload?.property) {
							payload.property = trackingPayload.property;
						}

						isTitleInList
							? trackWatchlistRemoveEvent(payload, contexts)
							: trackWatchlistAddEvent(payload, contexts);
					}

					if (sponsoredAd?.campaign?.node.nodeId === titleId && !isTitleInList) {
						TrackingHelper.trackEvent(
							ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
							{
								action: 'watchlist_clicked',
								label: `${titleId}_${sponsoredRecommendationPackageId}`,
								property: isSponsoredRecommendationCohort ? 'ad' : 'poster',
								value: isSponsoredRecommendationCohort ? 1 : 0,
							},
							contexts
						);
					}

					if (!isTitleInList) {
						addTitleToListToast(TitleListName.WATCHLIST, titleDetails, () =>
							setInWatchlist(titleId, true, titleDetails, trackingPayload)
						);
					} else {
						removeTitleFromListToast(TitleListName.WATCHLIST, titleDetails);
					}
				},
			})
			.then(data => {
				const payload: TitleQuickActionPayload = {
					titleObjectId: titleDetails.objectId,
					type: TitleListName.WATCHLIST,
					isTitleInList: isTitleInList,
					objectType: titleDetails.objectType,
				};
				trackingCallback && trackingCallback(payload);

				return data;
			});
	}

	function trackWatchlistAddEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.WATCHLIST, 'add', payload, contexts);
	}

	function trackWatchlistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.WATCHLIST, 'remove', payload, contexts);
	}

	return { onIsLoggedInChange, setInWatchlist };
}
